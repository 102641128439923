<i18n>
ru:
  recommended: Рекомендованные товары
ua:
  recommended: Рекомендовані товари
us:
  recommended: Recommended products
</i18n>

<template>
  <template v-if="menuStore.Recommended.size > 0">
    <lazy-cart-entwash-recommended
      v-if="appConfig.VueSettingsPreRun.CartLayout === 'Entwash'"
      :recommendations="recommendations"
    />
  </template>
</template>

<script setup lang="ts">
import type { GUID, HasID } from '@arora/common'

const appConfig = useAppConfig()

const clientStore = useClientStore()
const menuStore = useMenuStore()

const recommendations = computed<HasID[]>(() =>
  (clientStore.ClientState.data?.RecommendationsList ?? []).map((id) => {
    return { ID: id } as HasID
  })
)

watch(
  () => clientStore.ClientState.data?.RecommendationsList,
  async (newContent: GUID[] | undefined, oldContent: GUID[] | undefined) => {
    if (newContent && JSON.stringify(oldContent) !== JSON.stringify(newContent)) {
      await menuStore.updateRecommended(newContent)
    }
  },
  { deep: true }
)

onMounted(async () => {
  if (clientStore.ClientState.state === 'success') {
    await menuStore.updateRecommended(
      clientStore.ClientState.data?.RecommendationsList ?? []
    )
  }
})
</script>
