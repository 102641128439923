<template>
  <section v-if="appConfig.VueSettingsPreRun.CartLayout === 'Entwash'">
    <cart-entwash-one-stage v-if="appConfig.RestaurantSettingsPreRun.OneStageCart" />
    <cart-entwash-two-stage-first v-else />
  </section>
  <cart-common />
</template>

<script setup lang="ts">
const appConfig = useAppConfig()
</script>
